import { FC } from 'react'
import EditIcon from '../../../assets/icons/edit.svg';

const SubnetOverview:FC<{setEdit: Function, handleShow: Function, setTarget: Function, target: any }> = ({setEdit, handleShow, setTarget, target })=>{
    const Edit = (e : any)=>{
        setEdit(true)
        e.target.style.color = "#92F8FF"
        setTarget(e.target)
    }
    const Show = (e : any)=>{
        e.target.style.color = "#92F8FF"
        setTarget(e.target)
        handleShow(true)
    }
    return(
        <div className="dashboard-card  col-lg-8 col-md-12 col-sm-12 subnet-overview">
            <div className="subtitle">Subnet Overview</div>
            <div className='table-container'>
            <table className="subnet-table">
                <thead className="table-header">
                    <tr>
                        <th className="th-Mask">ID</th>
                        <th className="th-Catergory">Date</th>
                        <th className="th-Adress">Name</th>
                        <th className="th-RenewalDate">Category</th>
                        <th className="th-Free">Monthly</th>
                        <th className="th-Sitelist">Amount</th>
                        <th className="th-Update">Update</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Array(20).fill(0).map((_it, idx)=>
                            <tr key={idx}>
                                <td>2</td>
                                <td>18.10.2021</td>
                                <td>Netnut 100 GB Renewal</td>
                                <td>Residential</td>
                                <td><div style={{display:'flex', justifyContent:'center', alignItems:'center', paddingTop:'5px'}}><div className="td-free" style={{display:"flex", justifyContent:'center', alignItems:"center"}}>YES</div></div></td>
                                <td onClick={(e) => Show(e)} style={{cursor: "pointer", paddingLeft:"0px", paddingTop:"5px", paddingRight:"0px", paddingBottom:"5px"}}><div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><div className="td-Status" style={{display:"flex", justifyContent:'center', alignItems:"center"}}>799,00 €</div></div></td>
                                <td onClick={Edit}><button className="edit-btn"><img src={EditIcon} alt="editIcon"/></button>
                                </td>
                            </tr>
                        )
                    }

                </tbody>

            </table>
            </div>
        </div>
    )
}
export default SubnetOverview
