import React, {FC, useState} from 'react';
import './style.scss';
import NavBar from "../../components/Navbar";
import CustomSelect from "../../components/CustomSelect";
import CloseIcon from "../../assets/icons/close.svg";
import Avatar from "../../assets/avatar.svg";
import ShopIcon from "../../assets/icons/shop.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";

const Shop: FC = () => {
    const [plan, setPlan] = useState('1 GB/14 €');
    const [method, setMethod] = useState('Creditcard via Stripe ');
    const [openPurchase, setOpenPurchase] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const purchase = () => {
        setOpenPurchase(true);
    }

    const closePurchase = () => {
        setOpenPurchase(false);
    }

    const refreshClick = () => {
        setRefresh(true);
        setTimeout(()=>{
            setRefresh(false);
        }, 1000);
    }
    return (
        <div className="shop-content fade-in">
            <div className="row">
                <NavBar />
            </div>
            <div className="content mt-4">
                <div className="plan-option mb-lg-5">
                    <div className="row">
                        <div className="title">
                            Residential Plan Options
                        </div>
                    </div>
                    <div className="shop-cards mt-4">
                        <div className="shop-card ">
                            <div className="card-title">
                                Residential Mesh Pool
                            </div>
                            <div className="card-detail">
                                Perfect for Zalando. Insane Speeds. Proxy location as close as possible to Zalando Datacenters. Hitting Speeds around 30 ms.
                            </div>
                            <div className="card-footer">
                                <div className="group-input">
                                    <CustomSelect options={["1 GB/14 €", "2 GB/28 €", "4 GB/42 €"]} handler={setPlan} selected={plan} />
                                </div>
                                <button className="purchase" onClick={purchase}>Purchase</button>
                            </div>
                        </div>
                        <div className="shop-card">
                            <div className="card-title">
                                Residential Mesh Pool
                            </div>
                            <div className="card-detail">
                                Perfect for Zalando. Insane Speeds. Proxy location as close as possible to Zalando Datacenters. Hitting Speeds around 30 ms.
                            </div>
                            <div className="card-footer">
                                <div className="group-input">
                                    <CustomSelect options={["1 GB/14 €", "2 GB/28 €", "4 GB/42 €"]} handler={setPlan} selected={plan} />
                                </div>
                                <button className="purchase" onClick={purchase}>Purchase</button>
                            </div>
                        </div>
                        <div className="shop-card">
                            <div className="card-title">
                                Residential Mesh Pool
                            </div>
                            <div className="card-detail">
                                Perfect for Zalando. Insane Speeds. Proxy location as close as possible to Zalando Datacenters. Hitting Speeds around 30 ms.
                            </div>
                            <div className="card-footer">
                                <div className="group-input">
                                    <CustomSelect options={["1 GB/14 €", "2 GB/28 €", "4 GB/42 €"]} handler={setPlan} selected={plan} />
                                </div>
                                <button className="purchase" onClick={purchase}>Purchase</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="plan-option mb-lg-5">
                    <div className="row">
                        <div className="title">
                            Residential Plan Options
                        </div>
                    </div>
                    <div className="shop-cards mt-4">
                        <div className="shop-card ">
                            <div className="card-title">
                                Residential Mesh Pool
                            </div>
                            <div className="card-detail">
                                Perfect for Zalando. Insane Speeds. Proxy location as close as possible to Zalando Datacenters. Hitting Speeds around 30 ms.
                            </div>
                            <div className="card-footer">
                                <div className="group-input">
                                    <CustomSelect options={["1 GB/14 €", "2 GB/28 €", "4 GB/42 €"]} handler={setPlan} selected={plan} />
                                </div>
                                <button className="purchase" onClick={purchase}>Purchase</button>
                            </div>
                        </div>
                        <div className="shop-card">
                            <div className="card-title">
                                Residential Mesh Pool
                            </div>
                            <div className="card-detail">
                                Perfect for Zalando. Insane Speeds. Proxy location as close as possible to Zalando Datacenters. Hitting Speeds around 30 ms.
                            </div>
                            <div className="card-footer">
                                <div className="group-input">
                                    <CustomSelect options={["1 GB/14 €", "2 GB/28 €", "4 GB/42 €"]} handler={setPlan} selected={plan} />
                                </div>
                                <button className="purchase" onClick={purchase}>Purchase</button>
                            </div>
                        </div>
                        <div className="shop-card">
                            <div className="card-title">
                                Residential Mesh Pool
                            </div>
                            <div className="card-detail">
                                Perfect for Zalando. Insane Speeds. Proxy location as close as possible to Zalando Datacenters. Hitting Speeds around 30 ms.
                            </div>
                            <div className="card-footer">
                                <div className="group-input">
                                    <CustomSelect options={["1 GB/14 €", "2 GB/28 €", "4 GB/42 €"]} handler={setPlan} selected={plan} />
                                </div>
                                <button className="purchase" onClick={purchase}>Purchase</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="plan-option mb-lg-5">
                    <div className="row">
                        <div className="title">
                            Residential Plan Options
                        </div>
                    </div>
                    <div className="shop-cards mt-4">
                        <div className="shop-card ">
                            <div className="card-title">
                                Residential Mesh Pool
                            </div>
                            <div className="card-detail">
                                Perfect for Zalando. Insane Speeds. Proxy location as close as possible to Zalando Datacenters. Hitting Speeds around 30 ms.
                            </div>
                            <div className="card-footer">
                                <div className="group-input">
                                    <CustomSelect options={["1 GB/14 €", "2 GB/28 €", "4 GB/42 €"]} handler={setPlan} selected={plan} />
                                </div>
                                <button className="purchase" onClick={purchase}>Purchase</button>
                            </div>
                        </div>
                        <div className="shop-card">
                            <div className="card-title">
                                Residential Mesh Pool
                            </div>
                            <div className="card-detail">
                                Perfect for Zalando. Insane Speeds. Proxy location as close as possible to Zalando Datacenters. Hitting Speeds around 30 ms.
                            </div>
                            <div className="card-footer">
                                <div className="group-input">
                                    <CustomSelect options={["1 GB/14 €", "2 GB/28 €", "4 GB/42 €"]} handler={setPlan} selected={plan} />
                                </div>
                                <button className="purchase" onClick={purchase}>Purchase</button>
                            </div>
                        </div>
                        <div className="shop-card">
                            <div className="card-title">
                                Residential Mesh Pool
                            </div>
                            <div className="card-detail">
                                Perfect for Zalando. Insane Speeds. Proxy location as close as possible to Zalando Datacenters. Hitting Speeds around 30 ms.
                            </div>
                            <div className="card-footer">
                                <div className="group-input">
                                    <CustomSelect options={["1 GB/14 €", "2 GB/28 €", "4 GB/42 €"]} handler={setPlan} selected={plan} />
                                </div>
                                <button className="purchase" onClick={purchase}>Purchase</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal-drawer ${openPurchase ? "active" : "deactive"}`}>
                <div className="modal-drawer-content">
                    <img src={CloseIcon} alt="close" onClick={closePurchase} className="close-button"/>
                    <div className="drawer-content">
                        <div className="profile">
                            <div className="profile-user">
                                <span className="user-balance">$103.1</span>
                                <img src={Avatar} alt="avatar" className="user-avatar"/>
                                <span className="user-name">Vishy#1231</span>
                            </div>
                        </div>
                        <div className="cart-items">
                            <div className="cart-header">
                                <div className="cart-header-title">
                                    Cart <span className="number-of-items">(1 item)</span>
                                </div>
                                <div className="remove-item">
                                    <img src={CloseIcon} alt="remove item"/>
                                </div>
                            </div>
                            <div className="cart-item">
                                <div className="item-detail">
                                    <div className="item-image">
                                        <img src={ShopIcon} alt="Product Image"/>
                                    </div>
                                    <div className="item-content">
                                        <div className="item-title">
                                            2 GB Residential Proxies
                                        </div>
                                        <div className="shipping-date">
                                            valid till 24th Jan 2021
                                        </div>
                                    </div>
                                </div>

                                <div className="item-price">
                                    30 €
                                </div>
                            </div>
                        </div>
                        <div className="coupon">
                            <div className="discount-header">
                                Discount
                            </div>
                            <div className="discount-content">
                                <div className="input-discount">
                                    <input type="text" placeholder="Enter discount"/>
                                    <div className="apply-button">
                                        APPLIED
                                    </div>
                                </div>

                                <div className={`refresh`}>
                                    <img src={RefreshIcon} className={`${refresh && "clicked"}`} alt="Refresh" onClick={refreshClick}/>
                                </div>
                            </div>
                        </div>
                        <div className="bill">
                            <div className="price-list">
                                <div className="list-item">
                                    <div className="list-item-title">
                                        Subtotal
                                    </div>
                                    <div className="list-item">
                                        24 €
                                    </div>
                                </div>
                                <div className="list-item">
                                    <div className="list-item-title">
                                        Taxes (19% VAT)
                                    </div>
                                    <div className="list-item">
                                        6 €
                                    </div>
                                </div>
                                <div className="list-item">
                                    <div className="list-item-title">
                                        Discount 10 %
                                    </div>
                                    <div className="list-item-discount">
                                        -3 €
                                    </div>
                                </div>
                            </div>
                            <div className="divider"/>
                            <div className="total-price">
                                <div className="total-title">
                                    Total (including tax)
                                </div>
                                <div className="total-price-value">
                                    27 €
                                </div>
                            </div>
                        </div>
                        <div className="payment-method">
                            <div className="payment-header">
                                Payment Method
                            </div>
                            <div className="payment-content">
                                <div className="group-input">
                                    <CustomSelect options={["Creditcard via Stripe ", "Paypal"]} handler={setMethod} selected={method} />
                                </div>
                                <button className="checkout">Checkout</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Shop;
