import  { FC, useState} from 'react';
import CustomSelect from '../../../components/CustomSelect';

// @ts-ignore
import DatePicker from 'react-datepicker';
const AddSubnet: FC<{ edit: boolean, setEdit: Function}> = ({edit, setEdit})=> {

    // const [subnetMask, setSubnetMask] = useState("/24")
    const [category, setCategory] = useState("ISP")
    const [renewalDate, setRenewalDate] = useState("2021-10-27")
    // const [unbanned, setUnbanned] = useState(["Zalando", "Footlocker", "AW LAB"])
    const [date1, setDate1] = useState("14.11.2021");

    const [startDate, setStartDate] = useState(new Date());
    const years = range(1990, 2099);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const Edit = ()=>{
        setEdit(false)
    }
    function range(start: number, end: number) {
        // @ts-ignore
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }
    return (
        <div className="dashboard-card admin-card col-lg-4 col-md-12 col-sm-12 add-subnet">
            <div className="subtitle">Add Expense</div>
            <div className="subnet-input">
                <label className="mb-1 ml-1">Amount</label>
                <input type="string" defaultValue="799,00 €"/>
            </div>

            <div className="subnet-input">
                <label className="mb-1 ml-1">Name</label>
                <input type="string" defaultValue="Netnut 100 GB Renewal"/>
            </div>

            <div className="subnet-input">
                <label className="mb-1 ml-1">Category</label>
                <CustomSelect options={["Residential Proxy cost", "Option 2", "Option 3"]} handler={setCategory} selected={category} />
            </div>

            <div className="subnet-input">
                <label className="mb-1 ml-1">Monthly renewal</label>
                <CustomSelect options={["18.10.2021", "Footlocker", "AW LAB"]} handler={setDate1} selected={date1} />


            </div>

            <div className="subnet-input">
                <label className="mb-1 ml-1">Date</label>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    renderCustomHeader={({date,
                                             changeYear,
                                             changeMonth,
                                             decreaseMonth,
                                             increaseMonth,
                                             prevMonthButtonDisabled,
                                             nextMonthButtonDisabled,
                                         })=>(
                        <div className="picker-header">
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="prev-button">
                                {"<"}
                            </button>
                            <select
                                value={date.getFullYear()}
                                onChange={({ target: { value } }) => changeYear(value)}
                            >
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <select
                                value={months[date.getMonth()]}
                                onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                }
                            >
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="next-button">
                                {">"}
                            </button>
                        </div>
                    )}
                />
                {/*<CustomSelect options={["18.10.2021", "Footlocker", "AW LAB"]} handler={setDate1} selected={date1} />*/}
            </div>
            {!edit?  <button className="subnet-btn mx-auto admin-btn">Add Expense</button>: <div>
                <button className="subnet-btn admin-btn" onClick={Edit}>Save Changes</button>
                <button className="subnet-btn admin-btn">Delete Subnet</button>
                </div>}

        </div>
    )
}

export default AddSubnet;
