import {FC, useEffect, useState} from 'react'


const SiteList:FC<{handleShow: Function, target: any, setTarget: Function}> = ({handleShow, target, setTarget})=>{
    const close = ()=>{
        target.style.color = "#6F6F6F"
        setTarget(target);
        handleShow(false)
    }

    const unbanSites = ['Zalando', 'Footlocker', 'AW LAB'];
    const banSites = ['FTL Queue', 'SNS', 'KICKZ'];
    const [banSiteList, setBanSiteList] = useState(banSites);
    const [unbanSiteList, setUnbanSiteList] = useState(unbanSites);
    useEffect(() => {

    }, [])

    const removeUnban = (index: number) => {
        console.log(index);
        const removesite = unbanSiteList[index];
        const sites = unbanSiteList.filter((item)=> item !== unbanSiteList[index]);
        setUnbanSiteList(sites);

        const banlist = banSiteList.push(removesite);
        console.log(banlist);
        // setBanSiteList(ban);
    }

    const removeBan = (index: number) => {
        console.log('ban',index);
        const removesite = banSiteList[index];
        console.log(removesite);
        const banlist = banSiteList.filter((item) => item !== banSiteList[index]);
        setBanSiteList(banlist);
        const unbanlist = unbanSiteList.push(removesite);
        console.log(unbanlist);
    }
    // @ts-ignore
    return(
        <div className="sitelist dashboard-card col-sm-12 col-lg-4 col-md-12">
                <div className="subtitle">Sitelist</div>
                <div className="case">Unbanned on</div>
                <div className="unbanned">
                    <div className="">
                        {
                            unbanSiteList.map((item, index)=><button className="ban-btn" key={index} onClick={()=>removeUnban(index)}><span style={{color: "#ffffff", marginRight: "10px"}}>X</span>{item}</button>)
                        }
                    </div>
                </div>
                <div className="case">Banned on</div>
                <div className="banned">
                    <div className="">
                        {
                            banSiteList.map((item, idx)=><button className="unban-btn" key={idx} onClick={()=>removeBan(idx)}><span style={{color: "#ffffff", marginRight: "10px"}}>X</span>{item}</button>)
                        }
                    </div>
                </div>
                <button className="subnet-btn" onClick={close}>Close Sitelist</button>
        </div>
    )
}
export default SiteList
