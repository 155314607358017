import React, { FC } from 'react';

const FAQ: FC = () => {
    return (
        <div className="main-content fade-in">
            FAQ scene
        </div>
    );
}

export default FAQ;