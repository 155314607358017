import { FC } from 'react'
import EditIcon from '../../../assets/icons/edit.svg';
const SubnetOverview:FC<{setEdit: Function, handleShow: Function, setTarget: Function }> = ({setEdit, handleShow, setTarget })=>{
    const Edit = ()=>{
        setEdit(true)
    }
    const Show = (e : any)=>{
        e.target.style.color = "#92F8FF"
        setTarget(e.target)
        handleShow(true)
    }
    return(
        <div className="subnet-overview col-lg-8 dashboard-card">
            <div className="subtitle">Subnet Overview</div>
            <div className='table-container'>
                <table className="subnet-table">
                    <thead className="table-header">
                        <tr>
                            <th className="th-Mask">Mask</th>
                            <th className="th-Catergory">Catergory</th>
                            <th className="th-Adress">Adress</th>
                            <th className="th-RenewalDate">Renewal Date</th>
                            <th className="th-Free">Free</th>
                            <th className="th-Sitelist">Sitelist</th>
                            <th className="th-Update">Update</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Array(20).fill(0).map((_it, idx)=>
                                <tr key={idx}>
                                    <td>/24</td>
                                    <td>ISP</td>
                                    <td>192.168.545.0</td>
                                    <td>14.11.2021</td>
                                    <td><div style={{display:'flex', justifyContent:'center', alignItems:'center', paddingTop:'5px'}}><div className="td-free" style={{display:"flex", justifyContent:'center', alignItems:"center"}}>125</div></div></td>
                                    <td onClick={(e) => Show(e)} style={{cursor: "pointer", paddingLeft:"0px", paddingTop:"5px", paddingRight:"0px", paddingBottom:"5px"}}><div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><div className="td-Status" style={{display:"flex", justifyContent:'center', alignItems:"center"}}>Status</div></div></td>
                                    <td onClick={Edit}><button className="edit-btn"><img src={EditIcon} alt="editIcon"/></button></td>
                                </tr>
                            )
                        }
                    </tbody>

                </table>
            </div>
        </div>
    )
}
export default SubnetOverview
