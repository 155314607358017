import  { FC, useState} from 'react';
import CustomSelect from '../../../components/CustomSelect';
import CustomMultiSelect from '../../../components/CustomMultiSelect'
// @ts-ignore
import DatePicker from 'react-datepicker';
const AddSubnet: FC<{ edit: boolean, setEdit: Function}> = ({edit, setEdit})=> {

    const [subnetMask, setSubnetMask] = useState("/24")
    const [category, setCategory] = useState("ISP")
    const [renewalDate, setRenewalDate] = useState("2021-10-27")
    const [unbanned, setUnbanned] = useState(["Zalando", "Footlocker", "AW LAB"])
    const [startDate, setStartDate] = useState(new Date());
    const years = range(1990, 2099);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const Edit = ()=>{
        setEdit(false)
    }
    function range(start: number, end: number) {
        // @ts-ignore
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }

    return (
        <div className="dashboard-card admin-card col-sm-12 col-md-12 col-lg-4 add-subnet">
            <div className="subtitle">Add Subnet</div>
            <div className="subnet-input">
                <label className="mb-1 ml-1">Adress</label>
                <input type="string" defaultValue="192.168.545.0"/>
            </div>

            <div className="subnet-input">
                <label className="mb-1 ml-1">Subnet mask</label>
                <CustomSelect options={["/24", "Option 2", "Option 3"]} handler={setSubnetMask} selected={subnetMask}/>
            </div>

            <div className="subnet-input">
                <label className="mb-1 ml-1">Category</label>
                <CustomSelect options={["ISP", "Option 2", "Option 3"]} handler={setCategory} selected={category} />
            </div>

            <div className="subnet-input">
                <label className="mb-1 ml-1">Renewal Date</label>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    renderCustomHeader={({
                                             date,
                                             changeYear,
                                             changeMonth,
                                             decreaseMonth,
                                             increaseMonth,
                                             prevMonthButtonDisabled,
                                             nextMonthButtonDisabled,
                                         })=>(
                        <div className="picker-header" >
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="prev-button">
                                {"<"}
                            </button>
                            <select
                                value={date.getFullYear()}
                                onChange={({ target: { value } }) => changeYear(value)}
                            >
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <select
                                value={months[date.getMonth()]}
                                onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                }
                            >
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="next-button">
                                {">"}
                            </button>
                        </div>
                    )}
                />
                {/*<CustomSelect options={["14.11.2021", "Option 2", "Option 3"]} handler={setRenewalDate} selected={renewalDate} />*/}
                {/*<input type="date" value={renewalDate} min="2021-01-01" max="2099-12-31" onChange={(e) => setRenewalDate(e.target.value)}/>*/}
            </div>

            <div className="subnet-input">
                <label className="mb-1 ml-1">Unbanned</label>
                <CustomMultiSelect options={["Zalando", "Footlocker", "AW LAB"]} handler={setUnbanned} selected={unbanned} />
            </div>
            {!edit?  <button className="subnet-btn mx-auto admin-btn">Add Subnet</button>: <div>
                <button className="subnet-btn admin-btn" onClick={Edit}>Save Changes</button>
                <button className="subnet-btn admin-btn">Delete Expense</button>
                </div>}

        </div>
    )
}

export default AddSubnet;
