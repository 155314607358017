import React, { FC} from 'react';
import { Link } from 'react-router-dom';

interface IProps {
    title: string,
    link: string,
    icon: string,
    style: string,
    active: boolean
}

const SideItem: FC<IProps> = ({title, active, link, icon, style}) => {
    return (
            <li className={`my-2 ${active && "active"}`}>
                <Link to={link}>
                    <div className="icon">
                        {/*<img src={icon} alt={title} />*/}
                        <i className={style}/>
                    </div>
                    <div className="title">
                        {title}
                    </div>
                    <div className="r-border"/>
                </Link>
            </li>
    );
}

export default SideItem;
